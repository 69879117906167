import { AsyncPipe } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit, Signal, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DoubledChartComponent } from '@c/doubled-chart/doubled-chart.component';
import { ChartReadyEventModel, ChartType, CustomChartIndicators } from '@c/doubled-chart/doubled-chart.model';
import { IGetCustomIndicators, ITvChartSettings } from '@c/shared/symbol-chart/symbol-chart.model';
import {
  ChartingLibraryWidgetOptions,
  EntityId,
  IChartingLibraryWidget,
  IChartWidgetApi,
} from '@chart/charting_library';
import {
  ChartSaveData,
  Colors,
  EasternTimeZoneName,
  ExchangeCountriesCodes,
  getShowEntryAndExitOptionName,
  getShowTradeOptionName,
  isNumberHasValue,
  MinStockPriceLevel,
  ShowEntryAndExitOptions,
  TabletMinWidth,
  TechnicalIndicators,
  TradePositions,
  UserSettings,
} from '@const';
import { ProfitLossIndicatorOptions } from '@constants/indicators/profit-loss-indicator.constants';
import { TradesIndicatorOptions } from '@constants/indicators/trades-indicator.constants';
import { Macd } from '@core/business/trading-chart/macd';
import { MacdSignal } from '@core/business/trading-chart/macd-signal';
import { MacdValue } from '@core/business/trading-chart/macd-value';
import { ProfitLoss } from '@core/business/trading-chart/profit-loss';
import { Rsi } from '@core/business/trading-chart/rsi';
import { RSIValue } from '@core/business/trading-chart/rsi-value';
import { Stochastic } from '@core/business/trading-chart/stochastic';
import { StochasticValue } from '@core/business/trading-chart/stochastic-value';
import { LocalStorageService } from '@s/local-storage.service';
import { ObservableService } from '@s/observable.service';
import { PastPerformanceService } from '@s/past-performance.service';
import { ProcessedDataService } from '@s/processed-data.service';
import { ISymbol, SymbolsService } from '@s/symbols.service';
import { TradeReportService } from '@s/trade-report.service';
import { ITradingStrategy, TradingStrategiesService } from '@s/trading-strategies.service';
import { UserDataService } from '@s/user-data.service';
import { combineLatest, filter, Subject, Subscription, switchMap, tap } from 'rxjs';
import { CustomDropdown } from 'src/app/indicators/custom-dropdown.indicator';
import { LineIndicator } from 'src/app/indicators/line.indicator';
import { ProfitLossIndicator } from 'src/app/indicators/profit-loss.indicator';
import { TradesIndicator } from 'src/app/indicators/trades.indicator';

@Component({
  selector: 'app-trading-chart',
  templateUrl: './trading-chart.component.html',
  styleUrls: ['./trading-chart.component.css'],
  standalone: true,
  imports: [DoubledChartComponent, AsyncPipe],
})
export class TradingChartComponent implements OnInit, OnDestroy {
  protected readonly defaultChartType = ChartType.Day1;
  protected readonly chartSavedDataKey = ChartSaveData.PowerX;
  protected readonly isDeveloperAccount = this.observableService.isDeveloperAccount.getValue();
  protected readonly currentSymbol$ = this.observableService.symbol;
  protected readonly resetChart$ = new Subject<void>();
  protected readonly widgetOptionsOverrides: Partial<ChartingLibraryWidgetOptions> = {};
  protected readonly disabledIndicators: CustomChartIndicators[] = [
    CustomChartIndicators.LowestHighestClose,
    CustomChartIndicators.RobsIndicators,
    CustomChartIndicators.PowerX,
  ];

  private activeChart: IChartWidgetApi | null = null;
  private activeChartType: ChartType | null = null;
  private tvWidget: IChartingLibraryWidget | null = null;

  private selectedSymbol: Signal<ISymbol> = signal(null);

  private tradePosition: Signal<TradePositions> = signal(null);
  private firstTradingStrategy: Signal<ITradingStrategy> = signal(null);
  private secondTradingStrategy: Signal<ITradingStrategy> = signal(null);
  private showEntryAndExitOption: Signal<string> = signal(this.observableService.showEntryAndExitOption.getValue());
  private showTechnicalIndicatorsOption: Signal<string> = signal(
    this.observableService.showTechnicalIndicators.getValue(),
  );

  private indicatorIds: Array<EntityId> = [];
  private indicatorIdsWithAdjustablePrecision: Array<EntityId> = [];

  private readonly tradesIndicator = new TradesIndicator();
  private readonly profitLossIndicator = new ProfitLossIndicator();
  private readonly minStrikePriceLineIndicator = new LineIndicator('', Colors.Yellow);

  protected tvChartSettings: ITvChartSettings = {
    priceScale: {
      defaultPriceScale: 100,
      ccPriceScale: 10000,
    },
  };

  private entryAndExitsDropdown = new CustomDropdown('Entry & Exits:');
  private entryAndExitsOptions: string[];
  private entryAndExitIds: Array<LineIndicator> = [];
  private entryAndExitsValueIndex: number;

  private tradesOptions: TradesIndicatorOptions[];
  private tradesValueIndex: number;

  private techIndDropdown = new CustomDropdown('Tech Ind.:');

  desktopHeader = 201;
  mobileHeader = 451;
  maxChartHeight = 650;
  minIndicatorHeight = 92;

  private subscription = new Subscription();

  constructor(
    private observableService: ObservableService,
    private processedDataService: ProcessedDataService,
    private tradeReportService: TradeReportService,
    private userDataService: UserDataService,
    private symbolsService: SymbolsService,
    private tradingStrategiesService: TradingStrategiesService,
    private pastPerformanceService: PastPerformanceService,
    private localStorageService: LocalStorageService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.observableService.showTradeOption.subscribe(async () => {
        await this.loadSymbolData(
          this.currentSymbol$.getValue(),
          this.firstTradingStrategy(),
          this.secondTradingStrategy(),
        );
        await this.showEntryAndExists();
        await this.showTrades();
      }),
    );

    this.observableService.showEntryAndExitOption.subscribe(async () => {
      await this.loadSymbolData(
        this.currentSymbol$.getValue(),
        this.firstTradingStrategy(),
        this.secondTradingStrategy(),
      );
      await this.showEntryAndExists();
    });

    this.selectedSymbol = toSignal(
      this.currentSymbol$.pipe(
        filter((symbolId) => isNumberHasValue(symbolId)),
        switchMap((symbolId) => this.symbolsService.getById(symbolId)),
        tap((symbol) => this.setIndicatorsPrecision(symbol)),
      ),
      { injector: this.injector },
    );

    this.tradePosition = toSignal(this.observableService.tradePosition, { injector: this.injector });
    this.firstTradingStrategy = toSignal(
      this.observableService.firstTradingStrategyId.pipe(
        switchMap((tradingStrategyId) => this.tradingStrategiesService.getById(tradingStrategyId)),
      ),
      { injector: this.injector },
    );
    this.secondTradingStrategy = toSignal(
      this.observableService.secondTradingStrategyId.pipe(
        switchMap((tradingStrategyId) => this.tradingStrategiesService.getById(tradingStrategyId)),
      ),
      { injector: this.injector },
    );
    this.showEntryAndExitOption = toSignal(this.observableService.showEntryAndExitOption, { injector: this.injector });
    this.showTechnicalIndicatorsOption = toSignal(this.observableService.showTechnicalIndicators, {
      injector: this.injector,
    });

    combineLatest([
      this.observableService.tradePosition,
      this.observableService.firstTradingStrategyId,
      this.observableService.secondTradingStrategyId,
      this.observableService.showTechnicalIndicators,
    ]).subscribe(async () => {
      await this.showEntryAndExists();
      await this.showTrades();
    });

    combineLatest([
      toObservable(this.firstTradingStrategy, { injector: this.injector }),
      toObservable(this.secondTradingStrategy, { injector: this.injector }),
    ]).subscribe(async () => {
      await this.showEntryAndExitsDropdown();
      await this.showTradesDropdown();
      await this.showTechIndDropdown();
    });
  }

  protected async afterSavedDataRestored(chartWidget: IChartingLibraryWidget, chartType: ChartType): Promise<void> {
    this.tvWidget = chartWidget;
    this.activeChartType = chartType;
    await this.showEntryAndExitsDropdown();
    await this.showTradesDropdown();
  }

  public getChartShapeIdsToExclude = (): EntityId[] => {
    const entryExitIds = this.entryAndExitIds.map((line) => line.lineId);

    return [...this.tradesIndicator.getShapesIds(), ...entryExitIds, this.minStrikePriceLineIndicator.lineId];
  };

  protected getCustomIndicators: IGetCustomIndicators = (PineJS) => {
    const localStorageService = this.localStorageService;
    const observableService = this.observableService;

    return [
      new Stochastic().createStochasticIndicator(
        PineJS,
        observableService,
        localStorageService,
        'StochasticIndicator',
        '#1',
      ),
      new Stochastic().createStochasticIndicator(
        PineJS,
        observableService,
        localStorageService,
        'StochasticIndicatorWithName',
        'Stochastic',
      ),
      new StochasticValue().createStochasticIndicator(PineJS, observableService, localStorageService),
      new Rsi().createRsiIndicator(PineJS, observableService, localStorageService, 'RsiIndicator', '#2'),
      new Rsi().createRsiIndicator(PineJS, observableService, localStorageService, 'RsiIndicatorWithName', 'RSI'),
      new RSIValue().createRsiIndicator(PineJS, observableService, localStorageService),
      new Macd().createMacdIndicator(PineJS, observableService, localStorageService, 'MacdIndicator', '#3'),
      new Macd().createMacdIndicator(PineJS, observableService, localStorageService, 'MacdIndicatorWithName', 'MACD'),
      new MacdValue().createMacdIndicator(PineJS, observableService, localStorageService),
      new MacdSignal().createMacdSignalIndicator(PineJS, observableService, localStorageService),
      new ProfitLoss().createProfitLoss(PineJS, true, observableService, localStorageService),
      new ProfitLoss().createProfitLoss(PineJS, false, observableService, localStorageService),
    ];
  };

  public getTimescaleMarks = async (
    activeChart: IChartWidgetApi,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    symbol: ISymbol,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    timeZone: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    bars: unknown[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    resolution: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-unsafe-function-type
    onDataCallback: Function, // to use specific fn: (args: ArgsModel) => ResultModel
  ): Promise<unknown[]> => {
    if (!activeChart) {
      return;
    }

    this.showTrades();

    return [];
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public loadChartData = async (symbol: ISymbol): Promise<void> => {
    await this.loadSymbolData(
      this.currentSymbol$.getValue(),
      this.firstTradingStrategy(),
      this.secondTradingStrategy(),
    );
  };

  protected async onChartReady(event: ChartReadyEventModel): Promise<void> {
    this.activeChart = event.chart;
    this.activeChartType = event.chartType;

    if (this.activeChartType === ChartType.Day1) {
      await this.activeChart.createStudy('PowerX', false, true);
    }

    await this.showEntryAndExists();
    await this.showTrades();
    await this.showTechIndDropdown();
    await this.showProfitLossStudy();
    await this.showIndicators(this.selectedSymbol());
    if (this.selectedSymbol()?.country_code !== ExchangeCountriesCodes.CC) {
      await this.showMinPriceLine();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onSymbolChanged(symbol: ISymbol): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async loadSymbolData(
    securityId: number,
    firstTradingStrategy: ITradingStrategy,
    secondTradingStrategy: ITradingStrategy,
  ): Promise<void> {
    await Promise.all([
      this.processedDataService.get(securityId),
      this.tradeReportService.get(securityId, firstTradingStrategy?.id, false),
      this.tradeReportService.get(securityId, secondTradingStrategy?.id, false),
    ]);
  }

  private async onTradesValueUpdated(option: string): Promise<void> {
    this.tradesValueIndex = this.tradesOptions.indexOf(option as TradesIndicatorOptions);
    this.observableService.showTradeOption.next(this.tradesOptions[this.tradesValueIndex]);
    await this.userDataService.set(UserSettings.ShowTradeOption, this.tradesOptions[this.tradesValueIndex]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async getAllTrades(): Promise<any> {
    const tradeOption = this.observableService.showTradeOption.getValue();

    const tradingStrategy =
      tradeOption === getShowTradeOptionName(this.firstTradingStrategy())
        ? this.firstTradingStrategy()
        : this.secondTradingStrategy();

    const trades = await this.tradeReportService.get(this.selectedSymbol()?.security_id, tradingStrategy.id);

    return Object.fromEntries(
      Object.entries(trades).filter(
        ([, trade]) =>
          trade &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.tradePosition() === TradePositions.LongAndShort || (trade as any).position === this.tradePosition()),
      ),
    );
  }

  private async showTradesDropdown(): Promise<void> {
    if (this.activeChartType !== ChartType.Day1) {
      this.tradesIndicator.removeDropdown();
      return;
    }

    this.tradesOptions = [
      this.firstTradingStrategy().name,
      this.secondTradingStrategy().name,
      'None',
    ] as TradesIndicatorOptions[];

    this.observableService.showTradeOption.next(this.tradesOptions[this.tradesValueIndex] ?? 'None');

    this.tradesIndicator.drawDropdown(
      this.tvWidget,
      this.tradesOptions,
      this.onTradesValueUpdated.bind(this),
      () => this.observableService.showTradeOption.getValue() as TradesIndicatorOptions,
    );
  }

  private async showTrades(): Promise<void> {
    if (this.activeChartType !== ChartType.Day1) {
      this.tradesIndicator.removeTrades(this.activeChart);
      return;
    }

    const getTimezone = (): string => {
      const chartTimezone = this.activeChart?.getTimezoneApi().getTimezone().id;

      return chartTimezone ?? EasternTimeZoneName;
    };

    this.tradesIndicator.showTrades(
      this.activeChart,
      () => this.observableService.showTradeOption.getValue() as TradesIndicatorOptions,
      async () => await this.getAllTrades(),
      getTimezone.bind(this),
      true,
    );
  }

  private async showEntryAndExitsDropdown(): Promise<void> {
    if (!this.tvWidget) {
      return;
    }

    this.entryAndExitsDropdown.removeDropdown();

    if (this.activeChartType !== ChartType.Day1) {
      return;
    }

    this.entryAndExitsOptions = [this.firstTradingStrategy().name, this.secondTradingStrategy().name, 'Both', 'None'];

    this.observableService.showEntryAndExitOption.next(
      this.entryAndExitsOptions[this.entryAndExitsValueIndex] ?? 'None',
    );

    const onDropdownUpdate = async (option: string): Promise<void> => {
      this.entryAndExitsValueIndex = this.entryAndExitsOptions.indexOf(option);
      this.observableService.showEntryAndExitOption.next(this.entryAndExitsOptions[this.entryAndExitsValueIndex]);
      await this.userDataService.set(
        UserSettings.ShowEntryAndExitOption,
        this.entryAndExitsOptions[this.entryAndExitsValueIndex],
      );
    };

    this.entryAndExitsDropdown.drawDropdown(
      this.tvWidget,
      () => this.entryAndExitsOptions,
      onDropdownUpdate,
      () => this.observableService.showEntryAndExitOption.getValue(),
    );
  }

  private addHorizontalLineToChart(price, color): LineIndicator {
    if (!this.activeChart) {
      return;
    }

    const line = new LineIndicator('', color);
    line.showLine(this.activeChart, price);
    return line;
  }

  private async showEntryAndExists(): Promise<void> {
    this.removeEntryAndExists();

    if (this.showEntryAndExitOption() === ShowEntryAndExitOptions.None || this.activeChartType !== ChartType.Day1) {
      return;
    }

    const selectedSecutiryId = this.currentSymbol$.getValue();

    const [firstSignal, secondSignal, firstTrade, secondTrade] = await Promise.all([
      this.pastPerformanceService.get(selectedSecutiryId, this.firstTradingStrategy()?.id),
      this.pastPerformanceService.get(selectedSecutiryId, this.secondTradingStrategy()?.id),
      this.tradeReportService.getRecent(selectedSecutiryId, this.firstTradingStrategy()?.id, this.tradePosition()),
      this.tradeReportService.getRecent(selectedSecutiryId, this.secondTradingStrategy()?.id, this.tradePosition()),
    ]);

    const entries = [];
    const targetProfits = [];
    const stopLosses = [];

    const trades = [];

    if (
      (firstSignal || firstTrade) &&
      (this.showEntryAndExitOption() === ShowEntryAndExitOptions.Both ||
        this.showEntryAndExitOption() === getShowEntryAndExitOptionName(this.firstTradingStrategy())) &&
      (firstSignal.signal === 'BTO' ||
        firstSignal.signal === 'STO' ||
        ((firstSignal?.position || firstTrade?.position) &&
          firstSignal.signal !== 'BTC' &&
          firstSignal.signal !== 'STC'))
    ) {
      trades.push(firstSignal || firstTrade);
    }

    if (
      (secondSignal || secondTrade) &&
      (this.showEntryAndExitOption() === ShowEntryAndExitOptions.Both ||
        this.showEntryAndExitOption() === getShowEntryAndExitOptionName(this.secondTradingStrategy())) &&
      (secondSignal.signal === 'BTO' ||
        secondSignal.signal === 'STO' ||
        ((secondSignal?.position || secondTrade?.position) &&
          secondSignal.signal !== 'BTC' &&
          secondSignal.signal !== 'STC'))
    ) {
      trades.push(secondSignal || secondTrade);
    }

    trades.forEach((trade) => {
      if (!entries.find((e) => e === trade.signal_entry_price || e === trade.entry_price)) {
        entries.push(trade.signal_entry_price || trade.entry_price);
      }
      if (!targetProfits.find((e) => e === trade.signal_target_profit || e === trade.target_profit)) {
        targetProfits.push(trade.signal_target_profit || trade.target_profit);
      }
      if (!stopLosses.find((e) => e === trade.signal_stop_loss || e === trade.stop_loss)) {
        stopLosses.push(trade.signal_stop_loss || trade.stop_loss);
      }
    });

    entries.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.BondiBlue)));
    targetProfits.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.ForestGreen)));
    stopLosses.forEach((e) => this.entryAndExitIds.push(this.addHorizontalLineToChart(e, Colors.Pink)));
  }

  private removeEntryAndExists(): void {
    if (!this.activeChart) {
      return;
    }

    this.entryAndExitIds.forEach((line) => {
      line.removeLine(this.activeChart);
    });

    this.entryAndExitIds = [];
  }

  private async showTechIndDropdown(): Promise<void> {
    if (!this.tvWidget) {
      return;
    }

    this.techIndDropdown.removeDropdown();

    if (this.activeChartType !== ChartType.Day1) {
      return;
    }

    const onDropdownUpdate = async (option: string): Promise<void> => {
      this.observableService.showTechnicalIndicators.next(option);
      await this.userDataService.set(UserSettings.ShowTechnicalIndicators, option);
      await this.showIndicators(this.selectedSymbol());
    };

    this.techIndDropdown.drawDropdown(
      this.tvWidget,
      () => Object.values(TechnicalIndicators),
      onDropdownUpdate,
      () => this.observableService.showTechnicalIndicators.getValue(),
    );
  }

  private async showProfitLossStudy(): Promise<void> {
    if (this.activeChartType !== ChartType.Day1) {
      this.profitLossIndicator.removeProfitLoss(this.activeChart);
      return;
    }

    await this.profitLossIndicator.showProfitLossStudy(
      this.activeChart,
      () => ProfitLossIndicatorOptions.Show,
      () => true,
      () => [],
      async (): Promise<ISymbol> => {
        const currentSecurityId = this.currentSymbol$.getValue();
        return this.symbolsService.getById(currentSecurityId);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async showIndicators(symbol: ISymbol): Promise<void> {
    if (!this.activeChart) {
      return;
    }

    this.removeIndicators();
    let indicators = [];
    let indicatorIdsWithAdjustablePrecision = [];

    if (this.activeChartType !== ChartType.Day1) {
      return;
    }

    if (this.showTechnicalIndicatorsOption() === TechnicalIndicators.Show) {
      const [stochasticId, rsiId, macdId] = await Promise.all([
        this.activeChart.createStudy('StochasticIndicatorWithName', false, true),
        this.activeChart.createStudy('RsiIndicatorWithName', false, true),
        this.activeChart.createStudy('MacdIndicatorWithName', false, true),
      ]);

      indicators = indicators.concat([stochasticId, rsiId, macdId]);
      indicatorIdsWithAdjustablePrecision.push(macdId);
    }

    if (this.showTechnicalIndicatorsOption() === TechnicalIndicators.ShowValues) {
      const [stochasticId, stochasticValueId, rsiId, rsiValue, macdId, macdSignalIndicator, macdValue] =
        await Promise.all([
          this.activeChart.createStudy('StochasticIndicator', false, true),
          this.activeChart.createStudy('StochasticValueIndicator', false, true),
          this.activeChart.createStudy('RsiIndicator', false, true),
          this.activeChart.createStudy('RsiValueIndicator', false, true),
          this.activeChart.createStudy('MacdIndicator', false, true),
          this.activeChart.createStudy('MacdValueIndicator', false, true),
          this.activeChart.createStudy('MacdSignalIndicator', false, true),
        ]);

      this.activeChart.getStudyById(stochasticValueId).mergeUp();
      this.activeChart.getStudyById(stochasticValueId).changePriceScale('no-scale');
      this.activeChart.getStudyById(rsiValue).mergeUp();
      this.activeChart.getStudyById(rsiValue).changePriceScale('no-scale');
      this.activeChart.getStudyById(macdSignalIndicator).mergeUp();
      this.activeChart.getStudyById(macdSignalIndicator).changePriceScale('no-scale');
      this.activeChart.getStudyById(macdValue).mergeUp();
      this.activeChart.getStudyById(macdValue).changePriceScale(macdSignalIndicator);

      indicatorIdsWithAdjustablePrecision = indicatorIdsWithAdjustablePrecision.concat([
        macdId,
        macdValue,
        macdSignalIndicator,
      ]);
      indicators = indicators.concat([
        stochasticId,
        stochasticValueId,
        rsiId,
        rsiValue,
        macdId,
        macdValue,
        macdSignalIndicator,
      ]);
    }

    // if indicatorIds get previous indicators, remove them. Appears in case when user quickly switch symbols
    if (this.indicatorIds) {
      this.removeIndicators();
    }

    // // save new indicators ids
    this.indicatorIds = this.indicatorIds.concat(indicators);
    this.indicatorIdsWithAdjustablePrecision = indicatorIdsWithAdjustablePrecision;

    this.setChartSize();
    this.setIndicatorsPrecision(this.selectedSymbol());
  }

  private setChartSize(): void {
    if (!this.activeChart) {
      return;
    }
    const chartHeight =
      window.innerWidth > TabletMinWidth
        ? window.innerHeight - this.desktopHeader
        : window.innerHeight - this.mobileHeader;
    const panes = this.activeChart.getPanes();
    if (panes?.length && chartHeight >= this.maxChartHeight) {
      const allPanesHeight = [];
      panes.forEach((value, index) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        index === 0
          ? allPanesHeight.push(chartHeight - panes.length * this.minIndicatorHeight)
          : allPanesHeight.push(this.minIndicatorHeight);
      });
      this.activeChart.setAllPanesHeight(allPanesHeight);
    }
  }

  private setIndicatorsPrecision(symbol: ISymbol): void {
    if (!this.activeChart) {
      return;
    }

    const precision = symbol.exchange_code === ExchangeCountriesCodes.CC ? 4 : 2;

    for (const indicatorId of this.indicatorIdsWithAdjustablePrecision) {
      this.activeChart.getStudyById(indicatorId).applyOverrides({ precision });
    }
  }

  private removeIndicators(): void {
    if (!this.activeChart) {
      return;
    }

    while (this.indicatorIds.length) {
      this.activeChart.removeEntity(this.indicatorIds.pop());
    }

    this.indicatorIdsWithAdjustablePrecision = [];
  }

  private showMinPriceLine(): void {
    if (!this.activeChart) {
      return;
    }

    this.minStrikePriceLineIndicator.showLine(this.activeChart, MinStockPriceLevel);
  }
}
