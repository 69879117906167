import { Directive } from '@angular/core';
import { IChartingLibraryWidget, IDropdownApi } from '@chart/charting_library';

@Directive()
export class CustomDropdown {
  private dropdownApi: IDropdownApi | null = null;
  private title: string = '';

  constructor(title: string) {
    this.title = title;
  }

  public async drawDropdown(
    widget: IChartingLibraryWidget,
    getDropdownOptions: () => string[],
    onDropdownUpdate: (option: string) => Promise<void>,
    getSelectedOption: () => string,
  ): Promise<void> {
    if (!widget) {
      return;
    }

    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }

    const items = [...getDropdownOptions()].map((option) => ({
      title: option,
      onSelect: async (): Promise<void> => {
        this.dropdownApi.applyOptions({
          title: `${this.title} ${option}`,
        });

        await onDropdownUpdate(option);
      },
    }));

    const selectedOption = getSelectedOption();

    this.dropdownApi = await widget.createDropdown({
      title: `${this.title} ${selectedOption}`,
      items,
    });
  }

  public removeDropdown(): void {
    if (this.dropdownApi) {
      this.dropdownApi.remove();
    }
  }
}
