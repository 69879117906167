<app-grouped-watchlist
  [isActive]="isActive"
  [currentSymbol]="{ security_id: symbol }"
  [watchlistTableItems]="watchlistTableItems"
  [columns]="['left_trade_position', 'right_trade_position', 'symbol', 'country_code', 'company_name', 'created_date']"
  [columnHints]="{
    left_trade_position: 'Position is based on ' + firstTradingStrategy?.name,
    right_trade_position: 'Position is based on ' + secondTradingStrategy?.name,
  }"
  [smileyListType]="smileyListTypes.PowerX"
  [watchlistType]="watchlistTypes.PowerX"
  (symbolSelected)="setCurrentSymbol($event)"
  (symbolAdded)="addSymbolToWatchlist()"
  (symbolRemoved)="removeSymbolFromWatchlist()"
  #groupedWatchlist
  class="power-x-grouped-watchlist"
></app-grouped-watchlist>
